/** @format */

@font-face {
	font-family: 'font-bold';
	src: local('font'), url(./assets/fonts/Poppins-Bold.ttf) format('opentype');
	font-display: swap;
	font-weight: bold;
}
@font-face {
	font-family: 'font-primary';
	src: local('font'), url(./assets/fonts/Poppins-Regular.ttf) format('opentype');
	font-display: swap;
}

:root {
	--primary-color: #212f49; /*---------CHANGE HERE----------*/
	--secondary-color: #e5332a; /*---------CHANGE HERE----------*/
	--font-primary: 'font-primary'; /*---------CHANGE HERE----------*/
	--font-bold: 'font-bold'; /*---------CHANGE HERE----------*/
	--rating-radius: 0%; /*---------CHANGE HERE----------*/
}
